import { FC, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';

import { FloatButton } from 'antd';
import { HttpStatusCode } from 'axios';
import clsx from 'clsx';
import { debounce } from 'lodash';

import { createChat } from '@/api/chat/create-chat';
import { getChatById } from '@/api/chat/get-chat-by-id';
import { getChatList } from '@/api/chat/get-chat-list';
import { SocketService } from '@/api/services/socket.service';
import { ChatWindowBody } from '@/components/Chat/ChatWindowBody';
import { ChatHeader } from '@/components/Chat/Header/ChatHeader';
import { ChatContext } from '@/context/chat.context';
import { FeedbackContext } from '@/context/feeadback.context';
import { UserContext } from '@/context/user.context';
import useAsyncEffect from '@/hooks/use-async-effect';
import useMobile from '@/hooks/use-mobile';
import { ChatItem, ChatList, ChatParticipant, SocketEvents } from '@/types/chatItem';
import { ChatIcon } from '@/UI/icons/ChatIcon';

import styles from './styles.module.scss';

type Props = {
	defaultBindId?: string;
	defaultOpen?: boolean;
};

export const Chat: FC<Props> = ({ defaultOpen = false }) => {
	const { bindId, bindType, resetChatDefault } = useContext(ChatContext);
	const { user } = useContext(UserContext);
	const { notify } = useContext(FeedbackContext);
	const [open, setOpen] = useState(defaultOpen);
	const [loading, setLoading] = useState<boolean>(false);
	const anyChatWasOpen = useRef(false);
	const isMobile = useMobile();

	const [chats, setChats] = useState<ChatList>([]);

	const [activeChatId, setActiveChatId] = useState<string | null>(null);
	const [activeChatTitle, setActiveChatTitle] = useState<string>('');
	const [activeChatSubtitle, setActiveChatSubtitle] = useState<string>('');
	const [unreadTotal, setUnreadTotal] = useState(0);

	const socket = useRef(new SocketService('chats'));

	const loadChatList = async () => {
		if (loading) return;

		try {
			setLoading(true);
			const chatList = await getChatList();
			setChats(chatList);
			setUnreadTotal(chatList.reduce((acc, chat) => acc + chat.unread, 0));
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	const getReceiverParticipant = (participants: ChatParticipant[]) => {
		return participants.find((pt) => pt.id !== user.company_id);
	};

	const handleSocketDisconnected = () => {
		void socket.current.connect();
	};

	const handleNewMessageNotification = debounce((event: string) => {
		if (activeChatId) return;

		void loadChatList();
	}, 400);

	const handleChatSelect = (chat: ChatItem) => {
		const chatId = '' + chat.id;
		setActiveChatId(chatId);
		const receiver = getReceiverParticipant(chat.participants);
		setActiveChatTitle(chat.title);
		setActiveChatSubtitle(receiver.companyName);
	};

	const handleBackClick: MouseEventHandler<HTMLSpanElement> = (event) => {
		event.stopPropagation();
		setActiveChatId(null);
		setActiveChatTitle(null);
		setActiveChatSubtitle(null);
		resetChatDefault();
		void loadChatList();
	};

	const handleHeaderClick = () => {
		setOpen(!open);
	};

	const handleOpenByBindId = async () => {
		setLoading(true);
		try {
			let chat = chats.find((chat) => chat.bindId === bindId);
			if (!chat) {
				chat = await getChatById(bindId);
				if (!chat) {
					chat = await createChat(bindId, bindType);
				}
			}
			setOpen(true);
			const receiver = getReceiverParticipant(chat.participants);
			setActiveChatId(chat.id);
			setActiveChatTitle(chat.title);
			setActiveChatSubtitle(receiver?.companyName ?? 'Новый чат');
		} catch (e) {
			console.log(e);
			notify.info({
				message: 'Произошла ошибка при открытии чата. Попробуйте, пожалуйста, позже.',
				description: typeof e === 'string' ? e : null,
			});
			return resetChatDefault();
		} finally {
			setLoading(false);
		}
	};

	const handleChatWindowClose = () => {
		resetChatDefault();
		setActiveChatId(null);
		setActiveChatTitle(null);
		setActiveChatSubtitle(null);
		if (anyChatWasOpen.current) {
			void loadChatList();
			anyChatWasOpen.current = false;
		}
	};

	useEffect(() => {
		if (activeChatId) {
			anyChatWasOpen.current = true;
		}
	}, [activeChatId]);

	// Здесь обрабатываем открытие чата снаружи.
	// Внутрь передаём ID сущности, ID второго участника и имя второго участника
	// Это может быть название компании или имя менеджера
	useAsyncEffect(async () => {
		if (!bindId) return;

		void handleOpenByBindId();
	}, [bindId]);

	useEffect(() => {
		if (bindId && open) return;
		if (!open) {
			handleChatWindowClose();
		} else {
			void loadChatList();
		}
	}, [open]);

	useEffect(() => {
		void loadChatList();
	}, []);

	// Изначальное подключение к сокету и обработчики событий
	useEffect(() => {
		socket.current.on(SocketEvents.Closed, handleSocketDisconnected);
		socket.current.on(SocketEvents.NewMessageNotification, handleNewMessageNotification);
		socket.current.on(SocketEvents.Error, console.log);

		void socket.current.connect();

		return () => {
			socket.current.off(SocketEvents.NewMessageNotification, handleNewMessageNotification);
			socket.current.off(SocketEvents.Closed, handleSocketDisconnected);
			socket?.current?.close();
		};
	}, []);

	if (!open) {
		return (
			<FloatButton
				type="primary"
				icon={unreadTotal ? unreadTotal : <ChatIcon />}
				onClick={() => setOpen(true)}
				className={clsx(styles.floatButtonBody, unreadTotal && styles.hasUnread)}
				rootClassName={clsx(styles.floatButton, isMobile && styles.mobile)}
			/>
		);
	}

	return (
		<div className={clsx(styles.wrapper, isMobile && styles.mobile)}>
			<div className={styles.window}>
				<div className={styles.header}>
					<ChatHeader
						chatOpen={!!activeChatId}
						title={activeChatTitle}
						subtitle={activeChatSubtitle}
						onClick={handleHeaderClick}
						onBackClick={handleBackClick}
					/>
				</div>
				<div className={clsx(styles.body, isMobile && styles.mobile)}>
					<ChatWindowBody
						loading={loading}
						chats={chats}
						activeChatId={activeChatId}
						onChatSelect={handleChatSelect}
					/>
				</div>
			</div>
		</div>
	);
};
