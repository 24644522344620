import { FC, useEffect, useRef, useState } from 'react';

import { Spin } from 'antd';
import clsx from 'clsx';

import { getChatById } from '@/api/chat/get-chat-by-id';
import { readChatMessages } from '@/api/chat/read-chat-messages';
import { SocketService } from '@/api/services/socket.service';
import { ChatMessageListBody } from '@/components/Chat/ChatMessageList/ChatMessageListBody';
import { TextInput } from '@/components/Chat/TextInput/TextInput';
import useMobile from '@/hooks/use-mobile';
import { ChatItem, ChatMessage, SocketEvents } from '@/types/chatItem';

import styles from '@/components/Chat/ChatMessageList/styles.module.scss';

type Props = {
	chatId: string;
};

export const ChatMessageList: FC<Props> = ({ chatId }) => {
	const [chat, setChat] = useState<ChatItem>(null);
	const [messages, setMessages] = useState<ChatMessage[]>([]);

	const [loading, setLoading] = useState(false);

	const socket = useRef(new SocketService(`chat/${chatId}`));
	const [connectedStatus, setConnectedStatus] = useState<number>(WebSocket.CONNECTING);
	const isMobile = useMobile();

	const loadAndRead = async () => {
		await loadMessages();
		await readMessages();
	};

	const loadMessages = async () => {
		setLoading(true);
		try {
			const chat = await getChatById(chatId);
			setChat(chat);
			setMessages(chat.messages);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	const readMessages = async () => {
		await readChatMessages(chatId);
	};

	const addMessage = (message: ChatMessage) => {
		setMessages((prev) => {
			return [...prev, message];
		});
	};

	const handleSendMessage = (text: string) => {
		socket.current.send<string>({
			data: text,
		});
	};

	const handleNewMessage = (data: ChatMessage) => {
		addMessage(data);
	};

	const handleSocketConnected = () => {
		setConnectedStatus(WebSocket.OPEN);
		socket.current.on(SocketEvents.Message, handleNewMessage);
		void loadAndRead();
	};

	const handleSocketDisconnected = () => {
		setConnectedStatus(WebSocket.CLOSED);
		void socket.current.connect();
	};

	useEffect(() => {
		socket.current.on(SocketEvents.Connection, handleSocketConnected);
		socket.current.on(SocketEvents.Closed, handleSocketDisconnected);
		void socket.current.connect();

		return () => {
			socket.current.off(SocketEvents.Closed, handleSocketDisconnected);
			socket.current.off(SocketEvents.Connection, handleSocketConnected);
			socket.current.off(SocketEvents.Message, handleNewMessage);
			socket.current?.close();
		};
	}, []);

	if (loading || connectedStatus !== WebSocket.OPEN) {
		return (
			<div className={clsx(styles.spinnerWrapper, isMobile && styles.mobile)}>
				<Spin className={styles.spinner} size="large" spinning />
				<div className={styles.spinnerText}>Подключаемся...</div>
			</div>
		);
	}

	return (
		<>
			<ChatMessageListBody
				connectedStatus={connectedStatus}
				messages={messages}
				loading={loading}
				chat={chat}
			/>
			<TextInput
				key="input"
				chatId={chatId}
				onMessage={handleSendMessage}
				disabled={connectedStatus !== WebSocket.OPEN}
			/>
		</>
	);
};
