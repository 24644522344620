export enum BindType {
	Application = 'application',
	Purchase = 'purchase',
	Landing = 'landing',
}

export type ChatParticipant = {
	id: string;
	companyType: string;
	companyName: string;
	companyManagerName: string;
};

export type ChatItem = {
	id: string;
	bindId: string;
	participants: ChatParticipant[];
	messages: ChatMessage[];
	title: string;
	unread: number;
	creator: string;
	created: Date;
};

export type ChatMessage = {
	id: string;
	chatId: string;
	text: string;
	sender: ChatParticipant;
	created: Date;
};

export type CreateChatPayload = {
	bindId: string;
	bindType: BindType;
	title?: string;
};

export type ChatList = ChatItem[];

export type ChatMessageList = ChatMessage[];

export type IncomingSocketMessage<T> = {
	type: SocketMessageType;
	data: T;
};

export type PostChatMessage<T = string> = {
	data: T;
};

export enum SocketMessageType {
	Message = 'message',
	NewMessageNotification = 'new-message-notification',
}

export enum SocketEvents {
	Message = 'message',
	NewMessageNotification = 'new-message-notification',
	Connection = 'connection',
	Closed = 'closed',
	Error = 'error',
}
