import { RequestService } from '@/api/services/request.service';
import { ChatItem } from '@/types/chatItem';

export const getChatById = async (bindId: string | number): Promise<ChatItem> => {
	try {
		const request = RequestService.getInstance();

		const { data } = await request.get<ChatItem | null>(`api_v1/chats/${bindId}`);

		return data;
	} catch (error) {
		console.error(
			error?.response?.data ??
				error.message ??
				error.statusText ??
				'Ошибка при получении чата',
		);
		return null;
	}
};
