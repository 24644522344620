export const en = {
	Главная: 'Home',
	'Рыбной компании': 'Fish Company',
	'Найти склад': 'Find a warehouse',
	Складу: 'Warehouse',
	'Купить рыбу': 'Buy fish',
	'Заказать транспорт': 'Order transport',
	Войти: 'Sign in',
	Выйти: 'Log out',
	'Цифровой офис': 'Digital office',
	Лендинги: 'Landings',
	'Удалить черновик': 'Delete draft',
	'Перенести в корзину': 'Move to cart',
	Ошибка: 'Error',
	'Заполните необходимые поля на шагах, выделенных красным':
		'Fill in the required fields in the steps highlighted in red',
	'На запрашиваемую дату этот склад не может принять указанный объем':
		'On the requested date, this warehouse is unable to accept the specified volume',
	'Наименование склада': 'Name of warehouse',
	'Название склада': 'Warehouse name',
	'Личный кабинет': 'Account',
	'О платформе': 'About platform',
};
