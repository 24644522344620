import { FC, ReactNode } from 'react';

import Image, { StaticImageData } from 'next/image';

import { Button, Modal } from 'antd';
import cn from 'classnames';

import imagePopup from '@/assets/images/popup_forum_image.jpg';

import styles from './InfoModal.module.scss';

type Props = {
	isShownModal: boolean;
	title: string;
	content: string | ReactNode;
	image?: StaticImageData;
	okButtonText?: string;
	okButtonClassName?: string;
	cancelButtonClassName?: string;
	cancelButtonText?: string;
	onOk: (data?: any) => void;
	onCancel: (data?: any) => void;
};

export const InfoModal: FC<Props> = ({
	isShownModal,
	title,
	content,
	image,
	okButtonText,
	cancelButtonText,
	okButtonClassName,
	cancelButtonClassName,
	onOk,
	onCancel,
}) => {
	return (
		<Modal open={isShownModal} closable footer={null} onCancel={onCancel}>
			<div className={styles.container}>
				<div className={styles.title}>{title}</div>
				{image && (
					<div className={styles.image}>
						<Image className={styles.image} src={imagePopup} alt="pict" />
					</div>
				)}
				<div>{content}</div>
				<div className={styles.buttonBlock}>
					{okButtonText && (
						<Button
							id="infoSurveyOk"
							className={cn(styles.okButton, {
								[okButtonClassName]: okButtonClassName,
							})}
							size="large"
							type="primary"
							onClick={onOk}
						>
							{okButtonText}
						</Button>
					)}
					{cancelButtonText && (
						<Button
							id="infoSurveyCancel"
							className={cn(styles.cancelButton, {
								[cancelButtonClassName]: cancelButtonClassName,
							})}
							size="large"
							type="default"
							onClick={onCancel}
						>
							{cancelButtonText}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
};
