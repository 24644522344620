import { FC, useContext, useEffect, useRef } from 'react';

import { Empty } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/ru';

import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { ChatItem, ChatMessage } from '@/types/chatItem';

import styles from './styles.module.scss';

type Props = {
	chat: ChatItem;
	messages: ChatMessage[];
	connectedStatus: number;
	loading: boolean;
};

export const ChatMessageListBody: FC<Props> = ({ chat, messages, connectedStatus, loading }) => {
	const { user } = useContext(UserContext);
	const isMobile = useMobile();
	const messageListRef = useRef<HTMLDivElement>();
	const colors = [styles.receiverColorOne, styles.receiverColorTwo];
	const receiversColors = chat.participants
		.filter((pt) => pt.id !== user.company_id)
		.reduce((acc, pt, idx) => {
			acc[pt.id] = colors[idx];
			return acc;
		}, {});

	useEffect(() => {
		if (loading || connectedStatus !== WebSocket.OPEN || !messageListRef.current) return;

		messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
	}, [loading, connectedStatus, messages]);

	if (!messages.length)
		return (
			<Empty
				className={clsx(styles.empty, isMobile && styles.mobile)}
				description="Нет сообщений."
			/>
		);

	return (
		<div className={clsx(styles.list, isMobile && styles.mobile)} ref={messageListRef}>
			{messages.map((message) => {
				const isOwn = user.company_id === message.sender.id;
				const color = isOwn ? styles.sentMessageColor : receiversColors[message.sender.id];
				return (
					<div
						className={clsx(styles.item, isOwn ? styles.right : styles.left, color)}
						key={message.id}
					>
						<div className={styles.text}>{message.text}</div>
						<div className={styles.footer}>
							<div className={styles.name}>{message.sender.companyName}</div>
							<div className={styles.date}>
								{moment(message.created).format('DD.MM HH:mm')}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
